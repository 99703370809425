@use 'app-colors' as c;
@use 'app-variables' as v;

.dropdownItemList {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.dropdown {
  .dropdownMenuRight {
    transform: initial !important;
    left: auto !important;
    right: 0 !important;
    top: 100% !important;
  }
}


.dropdownItem {
  width: 100%;
  margin: 0 auto;
  margin-bottom: v.$spacer * .2;

  .iconContainer {
    width: v.$spacer * 1.5;
    text-align: center;
    margin-right: v.$spacer * .6;
  }

  .icon {
    color: c.$primary;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.mobileWrapper {
  position: fixed;
  bottom: v.$spacer * .5;
  left: v.$spacer * .5;
  right: v.$spacer * .5;
  z-index: 238;

  .mobileBackground {
    position: fixed;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, .675);
    z-index: 1;
  }

  .mobileMenu {
    position: relative;
    z-index: 2;

    .dropdownItemsContainer {
      background-color: c.$white;
      border-radius: v.$border-radius * 2;
      max-height: 75vh;
      overflow: auto;
    }

    .closeButtonContainer {
      margin-top: v.$spacer * .5;
      border-radius: v.$border-radius * 2;

      .button {
        border-radius: v.$border-radius * 2;
      }
    }

    .button {
      width: 100%;
      padding: v.$spacer * .75;
      background-color: c.$white !important;
      border-color: c.$white !important;
      text-align: left;
      font-weight: 600;

      &:hover, &:focus, &:active {
        background-color: c.$base !important;
        border-color: c.$base !important;
      }
    }
  }
}




