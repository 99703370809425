@use 'app-colors' as c;
@use 'app-variables' as v;

$headerColor: c.$white;

.header {
  height: v.$header-bar-height;
  position: fixed;
  top: 0;
  right: 0;
  left: v.$nav-full-width;
  z-index: v.$header-bar-zIndex;
  background-color: c.$primary;
  color: c.$white;
  padding: 0 v.$spacer * 2;

  .headerInnerContainer {
    display: flex;
    align-items: center;
    height: 100%;
    max-width: v.$main-max-width;
    margin: auto;
  }

  .logoContainer {
    // height: v.$header-bar-height;
    background-color: var(--primary);
    // max-width: 225px;

    a {
      display: flex;
      height: 100%;
      justify-content: center;
      color: c.$white;
      font-family: v.$font-family-headings;
      font-weight: 500;
      text-transform: uppercase;
      font-size: v.$font-size-base * 1.4;
      text-decoration: none;
    }

    .logo {
      max-height: 50px !important;
    }
  }

  .headerItems {
    flex: 0 0 auto;

    .switchAccountContainer {
      position: relative;
      display: flex;

      .switchAccountToggle {
        background: rgba(c.$white, .15);
        border-color: rgba(c.$white, .075);
        color: c.$white;
        font-weight: 600;
        // line-height: 1.6;
        .icon {
          vertical-align: middle;
          margin-right: v.$spacer * .4;
        }
      }
    }

    .accountInfoWrapper {

      .dropdown {
        .dropdownToggle {
          padding-left: v.$input-padding-x * 1.5;
          //padding-right: 2rem;
          //background-color: c.$primary-darker;
          //border-radius: 0;
        }
      }
    }

    .accountInfoContainer {
      height: 100%;
      color: $headerColor;
      display: flex;

      .kioskLogoutWrapper {
        align-self: center;

        .kioskLogoutBtn {
          vertical-align: middle;
          background-color: rgba(c.$white, .15);
          border-color: rgba(c.$white, .0625);
        }
      }

      .accountIconContainer {
        .icon {
          color: $headerColor;
          font-size: v.$font-size-base * 1.5;
        }
      }

      .dropdownIconContainer {
        .icon {
          color: $headerColor;
        }
      }

      .accountNameContainer {
        text-align: left;

        .name {
          line-height: 1.2;
          color: $headerColor;
          font-weight: 700;
        }
      }
    }
  }
}

.tooltip {
  z-index: 101;
}

@media screen and (max-width: 768px) {
  .header {
    padding: 0 v.$spacer;
  }
}

@media screen and (max-width: 465px) {
  .header {
    .accountInfoContainer {
      padding-left: v.$spacer * .5 !important;

      .dropdownToggle {
        padding-right: 0;
      }
    }
  }
}
