@use 'app-colors' as c;
@use 'app-variables' as v;

.wrapper {
  margin-top: v.$spacer * 4;
  padding-top: v.$spacer;
  padding-bottom: v.$spacer * 3;
  border-top: 1px solid c.$grey2;
  color: c.$grey4;
  font-size: v.$font-size-base * .9;

  .copyright {

  }

  .support {
    text-align: right;

    .title {
      font-weight: 600;
      margin-bottom: v.$spacer * .25;
    }
  }
}

:global {
  .rmd-compact {
    :local {
      .wrapper {
        margin-left: v.$main-content-padding;
        margin-right: v.$main-content-padding;
      }
    }
  }
}

@media screen and (max-width: 465px) {
  .wrapper {
    text-align: center;

    .support {
      margin-top: v.$spacer * 2;
      text-align: center;
    }
  }
}
